import { useState } from 'react'
import TopHeader from '../TopHeader'
import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons'
import { setToEmailAddress, getToEmailAddress } from '../../utils/roomsNavigator'
import useWindowDimensions from '../../utils/windowDimensions'


const Welcome = ({setScreen}) => {

const { height } = useWindowDimensions()

const [email, setEmail] = useState(getToEmailAddress('toEmailAddress') || '')
const [message, setMessage] = useState(null)

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .trim()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const next = () => {
	let ok = false
	if(Array.isArray(email))
	{
		//if multiple emails
		ok = email.every(validateEmail)
	}
	else if(typeof email === 'string')
	{
		if(email.includes(','))
		{
			ok = email.replace(/\s+/g, '').trim().split(',').every(validateEmail)
		}
		else if(email && validateEmail(email)){
			ok = true
		}
	}

	if(ok)
	{
		let emailArr
		if(Array.isArray(email))
		{
			//already in local storage
			emailArr = email.map(element => {
			  return element.replace(/\s+/g, '').trim();
			})
		}
		else if(typeof email === 'string')
		{
			emailArr = email.replace(/\s+/g, '').trim().split(',')
		}

		if(emailArr.length > 0)
		{
			setToEmailAddress(emailArr)
			setScreen("ChooseRooms")
		}
		else{
			setMessage('No email address')
		}
	}
	else
	{
		setMessage("Please add a valid email address")
	}
}

const previous = () => {
	setScreen("Welcome")
}

let containerHeight = `calc(${height}px - 70px)`

return(
	<>
	<TopHeader setScreen={setScreen}/>
	<div className="ps-5 pe-5 d-flex flex-column align-items-center justify-content-center"
		style={{height: containerHeight, maxHeight: containerHeight}}
	>
		<div className="welcomeTitle pt-4">Welcome</div>
		<div className="mt-5 d-flex flex-column align-items-center justify-content-center">
			<div className="welcomePropertyId">What is your email address?</div>
			<div className="mt-3 w-100">
				<input type="email" className="roundInput w-100 ps-2" defaultValue={email} onChange={(e) => {setMessage(""); setEmail(e.target.value?.trim())}}/>
			</div>
			
			{
				message && 
				<div className="mt-2">
					<span style={{color: 'red'}}><b>{message}</b></span>
				</div>
			}

			<div className="text-center mt-3 welcomeInfoText">
				Add multiple email recipients by adding multiple email addresses separated by a comma <b>address1@domain.com, address2@domain.com</b>
			</div>
			
		</div>

		<div className="mb-4 d-flex flex-row w-100 mt-auto justify-content-between">
			<div onClick={() => previous()}>
				<ChevronLeft size={25}/>
			</div>
			<div onClick={() => next()}>
				<ChevronRight size={25}/>
			</div>
		</div>
	</div>
	
	</>
)}

export default Welcome