import {useState, useEffect} from 'react'
import Loader from './components/Loader'
import Email from './components/Email'
import Welcome from './components/Welcome'
import ChooseRooms from './components/ChooseRooms'
import Room from './components/Room'
import RoomCheck from './components/Room/check'
import RoomDamage from './components/Room/roomDamage'
import RoomDetailDamage from './components/Room/roomDetailDamage'
import AddEmailAddress from './components/Welcome/email'

function App() {
  
  const [isLoading, setIsLoading] = useState(true);
  const [screen, setScreen] = useState("Welcome");

  useEffect(() => {
      setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return(
    isLoading ? 
      <Loader isLoading={isLoading}/>
    :
      <div className="App">
        { screen === "Welcome" && <Welcome setScreen={setScreen}/>}
        { screen === "AddEmailAddress" && <AddEmailAddress setScreen={setScreen}/>}
        { screen === "ChooseRooms" && <ChooseRooms setScreen={setScreen}/>}
        { screen === "Room" && <Room setScreen={setScreen}/>}
        { screen === "RoomCheck" && <RoomCheck setScreen={setScreen}/>}
        { screen === "RoomDamage" && <RoomDamage setScreen={setScreen}/>}
        { screen === "RoomDetailDamage" && <RoomDetailDamage setScreen={setScreen}/>}
        { screen === "Report" &&  <Email roomName="Generation" setScreen={setScreen}/>}
      </div>
  )
}

export default App;
