import TopHeader from '../TopHeader'
import useWindowDimensions from '../../utils/windowDimensions'

const Layout = ({children, roomName, titleStyle, isReport}) => {
	const { height } = useWindowDimensions()
	let containerHeight = `calc(${height}px - 70px)`

	return(
	<>
	<TopHeader/>
	<div className="ps-5 pe-5 pt-4 pb-3 d-flex flex-column align-items-center justify-content-center"
		style={{height: containerHeight, maxHeight: containerHeight}}
	>
		<div 
			className={`roomName w-100 d-flex flex-row align-items-start ${isReport ? 'justify-content-center' : 'justify-content-start'}`}
			style={titleStyle}
		>
			{roomName}
		</div>
		{children}
	</div>
	</>
)
}

export default Layout;
