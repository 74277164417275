import GenericCheck from './genericCheck'
import { getCurrentRoom } from '../../utils/roomsNavigator'

const Screen = ({setScreen}) => {

let curRoom = getCurrentRoom()

return(
	<GenericCheck 
		yesScreen="Room"
		noScreen="RoomDamage"
		backScreen="Room"
		nextScreen="RoomDamage"
		roomName={curRoom?.name}
		setScreen={setScreen}
	/>
)}

export default Screen