const windowGlobal = typeof window !== 'undefined' && window

const getRooms = () => {
	if(windowGlobal) 
		return JSON.parse(localStorage.getItem('rooms'))
	else
		return null
}

const getCurrentRoom = () => {
	if(windowGlobal)
	{
		let rooms = getRooms()
		let curIndex = getCurrentIndex()
		let curRoom = rooms[curIndex]
		return curRoom
	}
	else{
		return null
	}	
}

const setNextRoom = () => {
	if(windowGlobal)
	{
		let nextIndex = parseInt(localStorage.getItem('curIndex')) + 1
		localStorage.setItem('curIndex', nextIndex)
	}
}

const setPreviousRoom = () => {
	if(windowGlobal)
	{
		let curIndex = getCurrentIndex()
		if(curIndex > 0)
		{
			let previousIndex = parseInt(localStorage.getItem('curIndex')) - 1
			localStorage.setItem('curIndex', previousIndex)
		}
	}
}

const getCurrentIndex = () => {
	if(windowGlobal)
	{
		return parseInt(localStorage.getItem('curIndex'))
	}
}

const getCurrentQuestionIndex = () => {
	if(windowGlobal)
	{
		return parseInt(localStorage.getItem('curQuestion'))
	}
}

const createAttribute = (attributeName, attributeValue) => {
	let rooms = getRooms()
	let curRoom = getCurrentRoom()
	curRoom[attributeName] = attributeValue
	let curIndex = getCurrentIndex()
	rooms[curIndex] = curRoom
	localStorage.setItem('rooms', JSON.stringify(rooms))
} 

const createAttributeInQuestion = (attributeName, attributeValue) => {
	let rooms = getRooms()
	let curRoom = getCurrentRoom()
	let curQuestionIndex = getCurrentQuestionIndex() 
	curRoom.questions[curQuestionIndex][attributeName] = attributeValue
	let curIndex = getCurrentIndex()
	rooms[curIndex] = curRoom
	localStorage.setItem('rooms', JSON.stringify(rooms))
}

const getCurrentQuestion = () => {
	if(windowGlobal)
	{
		let curRoom = getCurrentRoom()
		let curQuestionIndex = getCurrentQuestionIndex()
		return curRoom.questions[curQuestionIndex]
	}
	else{
		return null
	}
}

const setNextQuestion = () => {
	if(windowGlobal)
	{
		let nextIndex = parseInt(localStorage.getItem('curQuestion')) + 1
		localStorage.setItem('curQuestion', nextIndex)
	}
}

const setPreviousQuestion = () => {
	if(windowGlobal)
	{
		let curQuestionIndex = getCurrentQuestionIndex()
		if(curQuestionIndex > 0)
		{
			let previousIndex = parseInt(localStorage.getItem('curQuestion')) - 1
			localStorage.setItem('curQuestion', previousIndex)
		}
	}
}

const isLastQuestion = () => {
	if(windowGlobal)
	{
		let curRoom = getCurrentRoom()
		let curQuestionIndex = getCurrentQuestionIndex()
		return (curRoom?.questions?.length - 1 === curQuestionIndex)
	}
	else{
		return null
	}
}

const isLastRoom = () => {
	if(windowGlobal)
	{
		let rooms = getRooms()
		let curIndex = getCurrentIndex()
		return (rooms?.length - 1 === curIndex)
	}
	else{
		return null
	}
}

const resetQuestionsIndex = () => {
	if(windowGlobal)
	{
		localStorage.setItem('curQuestion', '0')
	}
}

const resetRoomsIndex = () => {
	if(windowGlobal)
	{
		localStorage.setItem('curIndex', '0')
	}
}

const setPropertyId = (id) => {
	if(windowGlobal)
	{
		localStorage.setItem('propertyId', id)
	}
}

const getPropertyId = () => {
	if(windowGlobal)
	{
		return localStorage.getItem('propertyId')
	}
}

const setToEmailAddress = (email) => {
	if(windowGlobal)
	{
		localStorage.setItem('toEmailAddress', JSON.stringify(email))
	}
}

const getToEmailAddress = (attributeName) => {
	if(windowGlobal)
	{
		return JSON.parse(localStorage.getItem(attributeName))
	}
}

const getGeneralAttribute = (attributeName) => {
	if(windowGlobal)
	{
		return localStorage.getItem(attributeName)
	}
	return null
}

export 	{ getRooms, getCurrentRoom, getCurrentIndex, setNextRoom, createAttribute, 
		  createAttributeInQuestion, getCurrentQuestion, setNextQuestion, isLastQuestion, 
		  isLastRoom, resetQuestionsIndex, resetRoomsIndex, setPreviousRoom, setPreviousQuestion,
		  getCurrentQuestionIndex, setPropertyId, setToEmailAddress, getGeneralAttribute,
		  getToEmailAddress, getPropertyId
		}