import GenericDetailDamage from './genericDetailDamage'
import { getCurrentRoom } from '../../utils/roomsNavigator'

const Screen = ({setScreen}) => {

let curRoom = getCurrentRoom()

return(
	<GenericDetailDamage 
		backScreen="Room" 
		nextScreen="RoomDamage"
		roomName={curRoom?.name}
		setScreen={setScreen}
	/>
)}

export default Screen