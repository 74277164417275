import RoomPic from './picRoom'
import roompic from '../../images/room_damage.png'
import { getCurrentRoom } from '../../utils/roomsNavigator'

const Screen = ({setScreen}) => {

let curRoom = getCurrentRoom()

return(
	<>
		<RoomPic 
			setScreen={setScreen}
			instructions="Photograph a detail of the damage" 
			defaultPic={roompic}
			nextScreen="RoomDetailDamage"
			roomName={curRoom.name}
			takeDamagePic={true}
		/>
	</>
)}

export default Screen