import { useState, useEffect } from 'react'
import RoomLayout from '../Layout/roomlayout'
import { ChevronRight } from 'react-bootstrap-icons';
import yes from '../../images/yes.png'
import no from '../../images/no.png'
import { setNextRoom, getCurrentQuestion, getCurrentQuestionIndex, setNextQuestion, isLastQuestion, 
	isLastRoom, resetQuestionsIndex, createAttributeInQuestion, 
	setPreviousQuestion } from '../../utils/roomsNavigator'

const Screen = ({setScreen, roomName, question, yesScreen, noScreen, backScreen, nextScreen }) => {

const [currentQuestion, setCurrentQuestion] = useState(null)

const setNext = () => {
	createAttributeInQuestion("ok", "yes")

	if(isLastQuestion())
	{
		if(isLastRoom())
		{
			setScreen("Report")
		}
		else {
			setNextRoom()
			resetQuestionsIndex()
			setScreen(yesScreen)
		}
	}
	else {
		setNextQuestion()
		setCurrentQuestion(getCurrentQuestion())
		setScreen("RoomCheck")
	} 
}

const setNo = () => {
	createAttributeInQuestion("ok", "no")
	setScreen(noScreen)
}

const setBack = () => {
	if(getCurrentQuestionIndex() === 0)
		setScreen("RoomCheck")
	else
	{
		setPreviousQuestion()
		setCurrentQuestion(getCurrentQuestion())
	}
}

useEffect(()=>{
	setCurrentQuestion(getCurrentQuestion())
}, [])


return(
	<>
	<RoomLayout roomName={roomName} titleStyle={{marginTop: '70px'}}>
		<div className="mt-3 mb-4 w-100 d-flex flex-column align-items-center justify-content-center" style={{minHeight: '60vh'}}>
			<div className="roomCheckText text-center">{currentQuestion?.question}</div>
			<div className="mt-3 w-75 d-flex flex-row justify-content-between">
				<div onClick={() => setNext()}>
					<img src={yes} style={{height: '83px', width: '83px'}} alt="..."/>
				</div>
				<div onClick={() => setNo()}>
					<img src={no} style={{height: '83px', width: '83px'}} alt="..."/>
				</div>
			</div>
		</div>

		<div className="mb-4 d-flex flex-row w-100 mt-auto align-items-center justify-content-between">
			<div className="backText" onClick={() => setBack()}>Back</div>
			{/*<div onClick={() => setScreen(nextScreen)}>
				<ChevronRight size={25}/>
			</div>*/}
		</div>
	</RoomLayout>
	</>
)}

export default Screen