import React, { useEffect, useState } from 'react'
import RoomLayout from '../Layout/roomlayout'
import Report from '../PDF'
import { usePDF } from '@react-pdf/renderer'
import { getRooms, getGeneralAttribute, getToEmailAddress, getPropertyId } from '../../utils/roomsNavigator'
import ApiService from '../../api/services'

const Email = ({roomName, setScreen}) => {

const windowGlobal = typeof window !== 'undefined' && window
const rooms = getRooms()
const propertyId = getPropertyId()

const [ instance, updateInstance ] = usePDF({ document: <Report rooms={rooms} propertyId={propertyId}/> });
const [ sent, setSent ] = useState(false)
const [ message, setMessage ] = useState('')

const d = new Date()
const fileName = getGeneralAttribute("propertyId") + "_" + (d.getMonth() + 1) + "_" + d.getFullYear() + ".pdf"
const toAddress = getToEmailAddress('toEmailAddress')

const sendEmail = async(base64String) => {
	try{
		await ApiService.sendEmail(base64String, toAddress, fileName)
		setSent(true)
	}
	catch(err){
		console.error(err);
	}
}

useEffect(() => {
	if(windowGlobal && instance.blob && (instance.loading === false) && toAddress && !sent)
	{
		let base64String
		let reader = new FileReader()
	    reader.readAsDataURL(instance.blob)
	    reader.onloadend = function() {
	    	base64String = reader.result
	    	if(base64String)
	    	{
	    		let cleanUpBase64File = base64String.replace("data:application/pdf;base64,", "")
				sendEmail(cleanUpBase64File)
	    	}
		}
	}
}, [instance]);

if (instance.loading) return <div>Loading PDF report...</div>;

if (instance.error) return <div>Something went wrong while loading report</div>;

return(
<>
	<RoomLayout roomName={roomName} isReport={true}>
		{ sent &&
			<>	
			<div className="mt-3">Report sent by email!</div>
			<div className="text-decoration-underline mt-4" onClick={() => setScreen("Welcome")}>
				Back to the start
			</div>
			</>
		}
		{
			message &&
			<div>
				{message}
			</div>
		}
	</RoomLayout>
</>
)}

export default Email; 