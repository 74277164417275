import { useState } from 'react'
import RoomLayout from '../Layout/roomlayout'
import { ChevronRight } from 'react-bootstrap-icons';
import { createAttributeInQuestion, isLastQuestion, isLastRoom, setNextRoom,
 	resetQuestionsIndex, setCurrentQuestion, setNextQuestion } from '../../utils/roomsNavigator'

const Screen = ({setScreen, roomName, backScreen, nextScreen}) => {

const [ content, setContent ] = useState("")

const setNext = () => {
	
	if(content)
		createAttributeInQuestion("details", content)
	
	if(isLastQuestion())
	{
		if(isLastRoom())
		{
			setScreen("Report")
		}
		else {
			setNextRoom()
			resetQuestionsIndex()
			setScreen("RoomCheck")
		}
	}
	else {
		setNextQuestion()
		//setCurrentQuestion(getCurrentQuestion())
		setScreen("RoomCheck")
	} 
}

return(
	<>
	<RoomLayout roomName={roomName} titleStyle={{marginTop: '70px'}}>
		<div className="mt-3 mb-4 w-100 d-flex flex-column align-items-center justify-content-center" style={{minHeight: '60vh'}}>
			<div className="roomCheckText text-center mt-2">Detail the damage</div>
			<div className="mt-3">
				<textarea
					className="ps-3"
					style={{borderRadius: '25px', padding: '2px'}}
					rows={5}
          			cols={30} 
          			maxLength={230}
					defaultValue={content} 
					onChange={(e) => setContent(e.target.value)}
				/>
			</div>
			<div className="mt-1 maxLengthText w-100 d-flex flex-row justify-content-end">
				Max 230 characters
			</div>
		</div>

		<div className="mb-4 d-flex flex-row w-100 mt-auto align-items-center justify-content-between">
			<div className="backText" onClick={() => setScreen(backScreen)}>Back</div>
			{
				content &&
				<div onClick={() => setNext()}>
					<ChevronRight size={25}/>
				</div>
			}
		</div>
	</RoomLayout>
	</>
)}

export default Screen