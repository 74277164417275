import React from 'react';
import { Page, Text, View, Image, Font, Document, StyleSheet } from '@react-pdf/renderer';

const Report = ({rooms, propertyId}) => (
  <Document>
    <Page style={styles.body}>
      <View style={styles.topContent}>
        <Text style={styles.title}>Report {propertyId}</Text>
        <Text style={styles.subtitle}>Casa y Condo</Text>
      </View>

      { rooms && rooms.length > 0 && rooms.map((room, roomIndex) =>
        <React.Fragment key={roomIndex}>
      		<View style={styles.roomDetailsContainer} wrap={true}>
		       <Text style={styles.roomText}>{room.name}</Text>
		        <View style={styles.roomDetails}>
		          <View style={styles.roomDetailsRow}>
		            <View style={styles.roomDetailsColumn}>
		              <Text style={styles.roomDetailsColumnName}>Item</Text>
		            </View>
		            <View style={styles.roomDetailsColumn}>
		              <Text style={styles.roomDetailsColumnName}>Conditions</Text>
		            </View>
		            <View style={styles.roomDetailsColumn}>
		              <Text style={styles.roomDetailsColumnName}>Comments</Text>
		            </View>
		          </View>

              {
                room.questions.map((question, qIndex) => 
                  <View key={qIndex} style={styles.roomDetailsRow}>
                    <View style={styles.roomDetailsColumn}>
                      <Text style={styles.roomDetailsColumnItem}>{question.item}</Text>
                    </View>
                    <View style={styles.roomDetailsColumn}>
                      <Text style={question.ok === "yes" ? styles.roomDetailsColumnItemPassed : styles.roomDetailsColumnItemFailed}>{question.ok === "yes" ? "Passed" : "Failed"}</Text>
                    </View>
                    <View style={styles.roomDetailsColumn}>
                      <Text style={styles.roomDetailsColumnItemComments}>{question.details}</Text>
                    </View>
                  </View>
                )
              }
		          
		        </View>
		      </View>
		      
		      <View style={styles.imagesRowContainer} wrap={true}>
		        <Text style={styles.photosText}>Photos</Text>
		        <View style={styles.imagesRow} wrap={true}>
              {
                room.picture &&
                 <Image
                  style={styles.image}
                  src={room.picture && room.picture}
                />
              }
		          
              {
                room.questions.map((question, qIndex) =>
                  question.picture &&
                  <Image
                    key={qIndex}
                    style={styles.image}
                    src={question.picture}
                  />
                )
              }
		        </View>
		      </View>
		      
		      <View style={styles.borderLine}/>
        </React.Fragment>    
      )}    

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
  </Document>
);

Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v15/zN7GBFwfMP4uA6AR0HCoLQ.ttf'
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 5,
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 20,
    color: '#4C9FB9'
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 24,
    color: '#292D52'
  },
  roomText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 20,
    color: '#292D52'
  },
  topContent: {
    margin: 30, 
    flexDirection: 'row', 
    justifyContent: 'space-between'
  },
  roomDetailsContainer: {
    marginTop: 40, 
    marginLeft: 30, 
    marginRight: 30
  },
  roomDetails: {
    marginTop: 30, 
    borderRadius: '25px', 
    padding: '15 30', 
    border: '2px solid #DEDDDD'
  },
  roomDetailsRow: {
    flexDirection: 'row',
    marginTop: '20', 
    justifyContent: 'space-between'
  },
  roomDetailsColumn: {
    flexDirection: 'column',
    alignContent: 'start', 
    justifyContent: 'start',
    flexWrap: 'wrap', 
    width: '33.3%'
  },
  roomDetailsColumnName: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 14,
    color: '#5E5C5C'
  },
  roomDetailsColumnItem: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 14,
    color: '#292D52'
  },
  roomDetailsColumnItemPassed: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 14,
    color: 'green'
  },
  roomDetailsColumnItemFailed: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 14,
    color: 'red'
  },
  roomDetailsColumnItemComments: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 12,
    color: '#A8A8A8'
  },
  imagesRowContainer: {
    marginTop: 30, 
    marginLeft: 30, 
    marginRight: 30
  },
  imagesRow: {
    flexDirection: 'row',
    marginTop: 30, 
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  image: {
    marginTop: 20,
    width: 200,
    height: 200,
    objectFit: 'cover',
    borderRadius: 20,
    border: '2px solid #DEDDDD'
  },
  photosText: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 14,
    color: '#292D52'
  },
  borderLine: {
    marginTop: 30, 
    width: '50%', 
    border: '1px solid #DEDDDD', 
    marginLeft: 'auto', 
    marginRight: 'auto'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

export default Report;