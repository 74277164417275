import React, { useState } from 'react'
import RoomLayout from '../Layout/roomlayout'
import { ChevronRight } from 'react-bootstrap-icons'
import camerapic from '../../images/camera.png'
import animatedCamera from '../../images/animated_camera.gif'
import { createAttribute, createAttributeInQuestion } from '../../utils/roomsNavigator'
import Webcam from "react-webcam"
import ApiService from '../../api/services'

const Screen = ({setScreen, instructions, defaultPic, nextScreen, roomName, takeDamagePic}) => {

const setNext = () => {
	setScreen(nextScreen)
}

const savePic = (picLink) => {
	if(takeDamagePic)
		createAttributeInQuestion("picture", picLink)
	else 
		createAttribute("picture", picLink)
}

const [startCamera, setStartCamera] = useState(true)
const [image,setImage] = useState('')
const [message, setMessage] = useState('')
const webcamRef = React.useRef(null)

const capture = React.useCallback(
    async () => {
	    const imageSrc = webcamRef?.current?.getScreenshot();
	    
        try{
        	let cleanImage
		    if(imageSrc)
		    {
		    	setImage(imageSrc)
	        	cleanImage = imageSrc.replace(/^data:image\/[a-z]+;base64,/, "");

	        	let res = await ApiService.uploadImage(cleanImage)
	            //console.log({res})

	            if(res?.data?.uploadResult?.Location)
	                savePic(res?.data?.uploadResult?.Location)
	        }
	        else
	        {
	        	setMessage('No image taken')
	        }
        }
        catch(error)
        {
            console.error("Could not upload image")
        }
});

const videoConstraints = {
    facingMode: process.env.NODE_ENV === 'development' ? "user" : "environment"
    //in development show camera in selfie mode, in production show camera in environment mode
};

const WebcamCapture = () => {
    return (
        <div className="webcam-container overflow-hidden">
            <div className="webcam-img">
                {image === '' ? 
                    <Webcam
                        audio={false}
                        height="100%"
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width="100%"
                        style={{borderRadius: '25px', minHeight: '250px', minWidth: '250px'}}
                        videoConstraints={videoConstraints}
                        //screenshotQuality={1}
                        minScreenshotHeight={250}
                        minScreenshotWidth={250}
                    /> 
                    :
                    <img src={image} style={{objectFit: 'cover'}} alt="..."/>}
            </div>
        </div>
    );
}

return(
	<>
	<RoomLayout roomName={roomName} titleStyle={{marginTop: '70px'}}>
		<div className="roomPicText text-center mt-5">{instructions}</div>
		
		<div className="mt-3 mb-4 w-100">
			{
				startCamera ? 
				<WebcamCapture/>
				:
				<>
				{
					image && <img src={image} alt="..."/>
				}
				</>
			}	
			{
				message && 
				<div className="mt-3 d-flex align-items-center justify-content-center">
					{message}
				</div>
			}		
		</div>

		<div className="mt-3">
			{
				!startCamera ? 
				<img 
					src={animatedCamera} 
					className="animatedCamera" 
					alt="..."
					onClick={() => setStartCamera(!startCamera)}
				/>
				:
				<>
				{
					image ? 
					<img src={camerapic} 
						style={{maxHeight: '35px'}}
						onClick={() => {setImage(""); setStartCamera(true)}}
						alt="..."
					/>
					:
					<img src={camerapic} 
						style={{maxHeight: '35px'}} 
						alt="..."
						onClick={() => capture()}
					/>
				}
				
				</>
			}
		</div>

		<div className="mb-4 d-flex flex-row w-100 mt-auto align-items-center justify-content-between">
			<div className="backText" onClick={() => setScreen("ChooseRooms")}>Back</div>
			
			{ image &&
				<div onClick={() => setNext()}>
					<ChevronRight size={25}/>
				</div>
			}
			
		</div>
	</RoomLayout>
	</>
)}

export default Screen