import MoonLoader from "react-spinners/MoonLoader";

const Loader = ({isLoading}) => {

//Custom css for loader
  const override = `
  display: block;
  margin: 0 auto;
  border-color: red;
  `;

return(
	<div className="d-flex min-vh-100 flex-column align-items-center justify-content-center">
		<MoonLoader 
      color={'#36D7B7'} 
      isLoading={isLoading}
      css={override} 
      size={60} 
    />
	  <div className="font-face-rbt mt-4 loaderTitle">Casa y Condo</div>
  </div>
)}

export default Loader;