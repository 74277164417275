import { useState, useEffect } from "react";
import RoomLayout from "../Layout/roomlayout";
import { ChevronRight, Plus } from "react-bootstrap-icons";
import add from "../../images/add.png";
import remove from "../../images/remove.png";

const windowGlobal = typeof window !== "undefined" && window;

const Screen = ({ setScreen }) => {
	const [newRoom, setNewRoom] = useState("");
	const [chosenRooms, setChosenRooms] = useState([]);

	windowGlobal && localStorage.setItem("rooms", "[]");
	windowGlobal && localStorage.setItem("curIndex", "0");
	windowGlobal && localStorage.setItem("curQuestion", "0");

	const generalQuestions = [
		{ question: "Are the doors & windows ok?", item: "doors & windows" },
		{ question: "Is the wall & ceiling ok?", item: "wall & ceiling" },
		{ question: "Is the floor ok?", item: "floor" },
		{
			question: "Are the lightning & switches ok?",
			item: "lightning & switches",
		},
		{ question: "Is the furniture ok?", item: "furniture" },
		{
			question: "Are the utilities & gadgets ok?",
			item: "utilities & gadgets",
		},
	];

	const kitchenQuestions = [
		{ question: "Is the sink & tap ok?", item: "sink & tap" },
	];

	const bathroomQuestions = [
		{ question: "Is the bath or shower ok?", item: "bath & shower" },
		{ question: "Is the toilet ok?", item: "toilet" },
		{ question: "Is the sink or tap ok?", item: "sink & tap" },
	];

	const exteriorQuestions = [
		{ question: "Are the plants ok?", item: "plants" },
		{ question: "Is the furniture ok?", item: "furniture" },
		{ question: "Are the vehicles ok?", item: "vehicles" },
		{ question: "Is the pool or hut tub ok?", item: "pool & hut tub" },
		{ question: "Is the sauna ok?", item: "sauna" },
	];

	const [Rooms, setRooms] = useState([
		{ name: "Bedroom", questions: [...generalQuestions], counter: 0 },
		{
			name: "Bathroom",
			questions: [...generalQuestions, ...bathroomQuestions],
			counter: 0,
		},
		{
			name: "Kitchen",
			questions: [...generalQuestions, ...kitchenQuestions],
			counter: 0,
		},
		{ name: "Living room", questions: [...generalQuestions], counter: 0 },
		{ name: "Balcony", questions: [...generalQuestions], counter: 0 },
		{ name: "Study", questions: [...generalQuestions], counter: 0 },
		{ name: "Exterior", questions: [...exteriorQuestions], counter: 0 },
	]);

	const setNext = () => {
		if (chosenRooms?.length > 0) {
			let newRooms = [];

			for (let i = 0; i < chosenRooms.length; i++) {
				if (chosenRooms[i].counter === 1) {
					delete chosenRooms[i].counter;
					newRooms.push(chosenRooms[i]);
				} else if (chosenRooms[i].counter > 1) {
					for (let j = 0; j < chosenRooms[i].counter; j++) {
						newRooms.push({
							name: `${chosenRooms[i].name} ${j + 1}`,
							questions: chosenRooms[i].questions,
						});
					}
				}
			}
			windowGlobal &&
				localStorage.setItem("rooms", JSON.stringify(newRooms));

			setScreen("Room");
		}
	};

	const choose = (operation, name, questions, counter) => {
		let RoomsCopy = [...Rooms];

		let foundRoom = RoomsCopy.find((room) => room.name === name);

		if (operation === "add") {
			if (foundRoom) {
				foundRoom.counter = foundRoom.counter + 1;
				setRooms(RoomsCopy);
				setChosenRooms(RoomsCopy.filter((room) => room.counter > 0));
			}
		} else if (operation === "substract") {
			if (counter === 1) {
				if (foundRoom) {
					foundRoom.counter = 0;
				}
				setChosenRooms(RoomsCopy.filter((room) => room.counter > 0));
			} else if (counter > 1) {
				if (foundRoom) {
					foundRoom.counter = foundRoom.counter - 1;
					setRooms(RoomsCopy);
					setChosenRooms(
						RoomsCopy.filter((room) => room.counter > 0)
					);
				}
			}
		}
	};

	const addRoom = () => {
		if (newRoom)
			setRooms([
				...Rooms,
				{ name: newRoom, questions: [...generalQuestions], counter: 0 },
			]);
	};

	useEffect(() => {
		setNewRoom("");
	}, [Rooms]);

	return (
		<>
			<RoomLayout roomName="Room Choice">
				<div className="welcomeTitle mt-5">Choose your rooms</div>
				<div className="chooseText mt-2">
					{chosenRooms?.length} Rooms
				</div>
				<div className="mt-5 w-100 chooseRoomsOptions ps-2 pe-2 d-flex overflow-auto flex-column align-items-start justify-content-start">
					<div className="w-100">
						{Rooms.map((room, index) => {
							return (
								<div
									key={`default-${room.name}`}
									className="row mb-3 w-100"
								>
									<div className="col">{room.name}</div>
									<div className="col d-flex flex-row justify-content-between">
										<div
											onClick={() =>
												choose(
													"substract",
													room.name,
													room.questions,
													room.counter
												)
											}
										>
											<img src={remove} />
										</div>
										<div>{room.counter}</div>
										<div
											onClick={() =>
												choose(
													"add",
													room.name,
													room.questions,
													room.counter
												)
											}
										>
											<img src={add} />
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				<div className="mt-3 mb-5 w-100 border-top d-flex flex-column align-items-start">
					<div className="otherRoomText mt-3">Other</div>
					<div className="mt-3 w-100 d-flex flex-row align-items-center justify-content-between">
						<div>
							<input
								type="text"
								className="roundInput ps-2"
								value={newRoom || ""}
								onChange={(e) => setNewRoom(e.target.value)}
							/>
						</div>
						<div>
							<Plus size={25} onClick={() => addRoom()} />
						</div>
					</div>
				</div>

				<div className="mb-4 d-flex flex-row w-100 mt-auto align-items-center justify-content-between">
					<div
						className="backText"
						onClick={() => setScreen("Welcome")}
					>
						Back
					</div>
					{chosenRooms?.length > 0 && (
						<ChevronRight size={25} onClick={() => setNext()} />
					)}
				</div>
			</RoomLayout>
		</>
	);
};

export default Screen;
