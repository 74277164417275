import RoomPic from './picRoom'
import roompic from '../../images/roompic.png'
import { getCurrentRoom } from '../../utils/roomsNavigator'

const Screen = ({setScreen}) => {

let curRoom = getCurrentRoom()

return(
	<>
		<RoomPic 
			setScreen={setScreen}
			instructions="Take the whole view photo of the room" 
			defaultPic={roompic}
			nextScreen="RoomCheck"
			roomName={curRoom?.name}
			takeDamagePic={false}
		/>
	</>
)}

export default Screen