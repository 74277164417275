import { useState } from 'react'
import TopHeader from '../TopHeader'
import { ChevronRight } from 'react-bootstrap-icons';
import { setPropertyId, getGeneralAttribute } from '../../utils/roomsNavigator'
import useWindowDimensions from '../../utils/windowDimensions'

const Welcome = ({setScreen}) => {

const { height } = useWindowDimensions()

const [propId, setPropId] = useState(getGeneralAttribute("propertyId") || '')
const [message, setMessage] = useState(null)

const next = () => {
	if(propId)
	{
		setPropertyId(propId)
		setScreen("AddEmailAddress")
	}
	else
	{
		setMessage("Please add a property ID")
	}
}

let containerHeight = `calc(${height}px - 70px)`

return(
	<>
	<TopHeader setScreen={setScreen}/>
	<div className="ps-5 pe-5 d-flex flex-column align-items-center justify-content-center"
		style={{height: containerHeight, maxHeight: containerHeight}}
	>
		<div className="welcomeTitle pt-4">Welcome</div>
		<div className="mt-5 d-flex flex-column align-items-center justify-content-center">
			<div className="welcomePropertyId">What is your property id?</div>
			<div className="mt-3">
				<input type="text" className="roundInput ps-2" defaultValue={propId} onChange={(e) => setPropId(e.target.value)}/>
			</div>
			{
				message && 
				<div className="mt-2">
					{message}
				</div>
			}
			
		</div>

		<div className="mb-4 d-flex flex-row w-100 mt-auto justify-content-end" 
			onClick={() => next()}>
			<ChevronRight size={25}/>
		</div>
	</div>
	
	</>
)}

export default Welcome