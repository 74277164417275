import axios from 'axios';

const API_URL = process.env.REACT_APP_SERVERLESS_API_URL;

class ApiService {
  async uploadImage(file) {
    //base64 encoded file
    try{
      const res = await axios.post(API_URL + "/upload-image", {
        file: file,
      });
      return res;
    }
    catch(err)
    {
      console.error(err);
      return null
    }
  }

  async sendEmail(pdfFile, toAddress, fileName) {
    //base64 encoded pdf file
    try{
      const res = await axios.post(API_URL + "/send-email", {
        base64Pdf: pdfFile,
        toAddress: toAddress,
        fileName: fileName
      });
      return res;
    }
    catch(err)
    {
      console.error(err);
      return null
    }
  }
}

export default new ApiService();